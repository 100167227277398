
.market-data {
    height: auto;
    display: flex;
    flex-direction: column;
}
.path {
    display: flex;
    margin: 42px 0 0 40px;
    color: #5c5c6f;
    .item {
            display: flex;
            justify-content: center;
            align-items: center;
        .text {
            transition: all 0.3s;
            cursor: pointer;
        }
        .text:hover {
            color: #2821fc;
        }
    }
    .current {
            color: #ababbb;
    }
}
.big-title {
    font-size: 16px;
    color: #fff;
    margin: 20px 0 0 39px;
}
.flow-proportion-chart {
    width: 95.25%;
    // flex: 1;
    height: 443px;
    margin: 20px 41px 0 39px;
    background: #0c0e3f;
    box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
    border-radius: 6px;
}
.life-service {
    font-size: 16px;
    color: #fff;
    margin: 40px 0 0 43px;
}
.life-service-chart {
    display: grid;
    grid-template-columns: repeat(3, 32.2%);
    grid-template-rows: 320px 380px 511px 320px;
    grid-gap: 20px;
    width: 95.25%;
    height: auto;
    margin: 20px 0 20px 40px;
}
.life-service-chart .item {
    background: #0c0e3f;
    box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
    border-radius: 6px;
}
.life-service-chart .item1 {
    grid-column: 1/4;
    background: #0c0e3f;
    box-shadow: 0px 0px 10px 0px rgba(11, 12, 28, 0.04);
    border-radius: 6px;
}
.activeTime-title {
    color: #fff;
    font-size: 14px;
    margin: 20px 0 0 25px;
}
.activeTime-chart {
    width: 96%;
    height: 285px;
    margin: 41px 0 0 41px;
}
.nat-region-chart {
    width: 100%;
    height: 420px;
}
.paging-box {
    width: 220px;
    height: 10px;
    background: #0c0e3f;
    margin-right: 98px;
    float: right;
}
.natRegion-title {
    color: #fff;
    font-size: 14;
    font-weight: 400;
    margin: 19px 0 0 21px;
}
.sub-text {
    // float: right;
    width: 35%;
    font-size: 14px;
    margin: 0 0 0 50%;
    color: rgba(255, 255, 255, .5);
}
::v-deep .el-pagination {
        .btn-prev {
            color: #999;
            background: #0c0e3f;
        }
        .btn-next {
            color: #999;
            background: #0c0e3f;
        }
    }

::v-deep .pages-center .el-pager .number {
    color: #fff;
    background: #0c0e3f;
}
::v-deep .pages-center .el-pager .active {
    background: #2821FC;
    color: #fff;
}
::v-deep .pages-center .el-pager .btn-quicknext {
    color: #fff;
    background: #0c0e3f;
}
::v-deep .pages-center .el-pager .btn-quickprev {
    color: #fff;
    background: #0c0e3f;
}
.ranking{position: absolute;left: 49.6%;top: 30px;}
    @media screen and (min-width:900px) and (max-width:1300px){
        #s-map .ranking{position: absolute;left: 39.6%;}
    }
    @media screen and (min-width:1300px) and (max-width:1400px){
        #s-map .ranking{position: absolute;left: 43.6%;}
    }
    @media screen and (min-width:1400px) and (max-width:1700px){
        #s-map .ranking{position: absolute;left: 46.6%;}
    }
    @media screen and (min-width:1700px) and (max-width:1900px){
        #s-map .ranking{position: absolute;left: 47.6%;}
}
